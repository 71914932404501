import React, { SVGProps } from 'react';

export const Funny = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M4.89057 4.35752C4.31867 3.61283 3.19503 3.61283 2.62313 4.35752C2.3204 4.75172 1.75502 4.82618 1.36032 4.52385C0.965609 4.22152 0.891045 3.65688 1.19377 3.26268C2.4867 1.57911 5.02699 1.57911 6.31993 3.26268C6.62265 3.65688 6.54809 4.22152 6.15338 4.52385C5.75868 4.82618 5.1933 4.75172 4.89057 4.35752Z"
        fill="currentcolor"
      />
      <path
        d="M1.05162 9.68525C0.810561 8.84265 1.44407 8.00388 2.32154 8.00388H13.6785C14.5559 8.00388 15.1894 8.84264 14.9484 9.68525L14.9191 9.78775C14.0364 12.8729 11.2128 15 8 15C4.78716 15 1.96358 12.8729 1.08094 9.78775L1.05162 9.68525Z"
        fill="currentcolor"
      />
      <path
        d="M11.1094 4.35752C11.6813 3.61283 12.8049 3.61283 13.3768 4.35752C13.6796 4.75172 14.245 4.82618 14.6397 4.52385C15.0344 4.22152 15.1089 3.65688 14.8062 3.26268C13.5133 1.57911 10.973 1.57911 9.68005 3.26268C9.37733 3.65688 9.45189 4.22152 9.8466 4.52385C10.2413 4.82618 10.8067 4.75172 11.1094 4.35752Z"
        fill="currentcolor"
      />
    </svg>
  );
};
