import React, { SVGProps } from 'react';

export const Pen = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.98599 12.7118L13.3438 4.3539C13.8127 3.88506 13.8127 3.12493 13.3438 2.65609C12.875 2.18725 12.1148 2.18725 11.646 2.6561L3.28817 11.014C3.1174 11.1847 3.0021 11.403 2.95734 11.6403L2.63138 13.3686L4.35959 13.0426C4.59691 12.9978 4.81521 12.8825 4.98599 12.7118ZM14.6172 1.38273C13.4451 0.210635 11.5447 0.21064 10.3726 1.38274L2.0148 9.7406C1.58788 10.1675 1.29964 10.7133 1.18773 11.3066L0.508649 14.907C0.44336 15.2532 0.746768 15.5566 1.09292 15.4913L4.69335 14.8122C5.28666 14.7003 5.83243 14.4121 6.25935 13.9851L14.6172 5.62726C15.7893 4.45516 15.7893 2.55482 14.6172 1.38273Z"
        fill="currentcolor"
      />
      <path
        d="M9.94042 1.81495L14.185 6.05949L12.9116 7.33284L8.66706 3.08831L9.94042 1.81495Z"
        fill="currentcolor"
      />
    </svg>
  );
};
