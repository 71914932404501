import React, { SVGProps } from 'react';

export const Eye = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 14" {...props}>
      <path
        d="M9.34998 7.00001C9.34998 7.74559 8.74556 8.35001 7.99998 8.35001C7.25439 8.35001 6.64998 7.74559 6.64998 7.00001C6.64998 6.25442 7.25439 5.65001 7.99998 5.65001C8.74556 5.65001 9.34998 6.25442 9.34998 7.00001Z"
        fill="currentcolor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8194 7.69347C16.0579 7.25699 16.0579 6.74301 15.8194 6.30653C14.9244 4.66829 12.3036 0.695312 7.93751 0.695312C3.54419 0.695312 1.01021 4.71808 0.168337 6.33695C-0.0499272 6.75666 -0.0499274 7.24334 0.168336 7.66305C1.01021 9.28192 3.54419 13.3047 7.93751 13.3047C12.3036 13.3047 14.9244 9.33171 15.8194 7.69347ZM7.99998 10.15C9.73967 10.15 11.15 8.7397 11.15 7.00001C11.15 5.26031 9.73967 3.85001 7.99998 3.85001C6.26028 3.85001 4.84998 5.26031 4.84998 7.00001C4.84998 8.7397 6.26028 10.15 7.99998 10.15Z"
        fill="currentcolor"
      />
    </svg>
  );
};
